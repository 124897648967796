import styles from "./TechnicalDetails.module.scss";

const TechnicalDetails = () => {
  return (
    <div className={styles.section}>
      <div className={styles.section__left}>
        <h2>Software and Applications</h2>
        <div className={styles.section__left__content}>
          <div>
            <p>Figma</p>
            <p>Framer</p>
            <p>InVision</p>
            <p>Sketch</p>
            <p>Photoshop</p>
            <p>Craft</p>
          </div>
          <div>
            <p>InDesign</p>
            <p>Nolt</p>
            <p>Marvel</p>
            <p>Jira</p>
            <p>Slack</p>
            <p>Asana</p>
          </div>
        </div>
      </div>

      <div className={styles.section__right}>
        <div className={styles.singleColumn}>
          <h2>Skills</h2>
          <p>Team leadership</p>
          <p>Commercial awareness</p>
          <p>Presentation Strategy</p>
          <p>User Research</p>
          <p>Wireframing</p>
          <p>Storyboarding</p>
        </div>
        <div className={styles.singleColumn}>
          <h2>Setup</h2>
          <p>MacBook Pro 16" M1</p>
          <p>Dell UltraSharp 27</p>
          <p>AirPods</p>
          <p>iPhone 13 Mini</p>
        </div>
      </div>
    </div>
  );
};

export default TechnicalDetails;

import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";
import cv from "../../images/cv.pdf";

const Footer = ({ lightMode }) => {
  return (
    <div className={`${styles.section} ${styles.position}`}>
      <div className={styles.section__social}>
        <p
          class={
            lightMode ? `${styles.linkHoverLight}` : `${styles.linkHoverDark}`
          }
        >
          Elsewhere{" "}
          <span>
            <a
              href="https://www.linkedin.com/in/milovaneric/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn,
            </a>
          </span>{" "}
          <span>
            <a
              href="https://twitter.com/EricMilovan"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter,
            </a>
          </span>{" "}
          <span>
            <a
              href="https://dribbble.com/milovaneric"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dribbble
            </a>
          </span>
        </p>
      </div>
      <div className={styles.section__moreInfo}>
        <a href="mailto:hello@erameister.com">
          <button
            className={styles["section__moreInfo__button--primary"]}
            id={!lightMode ? `` : `${styles.writeLight}`}
          >
            Write <span>hello@erameister.com</span>
          </button>
        </a>
        <p id={styles.mobile}>👋 Looking for help? Feel free to reach out!</p>
        <a href={cv} target="_blank" rel="noopener noreferrer">
          <button
            className={styles["section__moreInfo__button--secondary"]}
            id={!lightMode ? `` : `${styles.buttonLight}`}
          >
            View my CV
          </button>
        </a>

        <Link to="/contact">
          <button
            className={styles["section__moreInfo__button--tercial"]}
            id={!lightMode ? `` : `${styles.buttonLight}`}
          >
            Get in Touch
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Footer;

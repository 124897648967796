import styles from "./Introduction.module.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Introduction = ({ text1, text2, text3 }) => {
  const { pathname } = useLocation();
  const [about, setAbout] = useState(false);

  useEffect(() => {
    if (pathname === "/about") {
      setAbout(true);
    }
  }, [pathname]);

  return (
    <div className={styles.section}>
      <div
        className={styles.section__intro1}
        id={about ? `${styles.about}` : ""}
      >
        {!about && <h1>{text1}</h1>}
        {about && <p>{text1}</p>}
      </div>
      <div
        className={styles.section__intro2}
        id={about ? `${styles.about}` : `${styles.home}`}
      >
        <p>{text2}</p>
        <p>{text3}</p>
      </div>
    </div>
  );
};

export default Introduction;

import ContactForm from "../ContactForm/ContactForm";
import styles from "./Contact.module.scss";
import cv from "../../images/cv.pdf?filename=Milovan_Eric_CV.pdf";

const Contact = ({ lightMode }) => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.section__info}>
          <div className={styles.mobile_wrapper}>
            <h1>Let’s make cool stuff together.</h1>
            <p>
              Let me help you become even greater at what you do. Fill out the
              following form and I will get back to you in next 24 hours.
            </p>
          </div>
          <div id={styles.desktop}>
            <h2>Email</h2>
            <p>hello@erameister.com</p>
          </div>
          <div
            id={styles.desktop}
            class={
              lightMode ? `${styles.linkHoverLight}` : `${styles.linkHoverDark}`
            }
          >
            <h2>Elsewhere</h2>
            <div
              class={
                lightMode
                  ? `${styles.linkHoverLight}`
                  : `${styles.linkHoverDark}`
              }
            >
              <a
                href="https://www.linkedin.com/in/milovaneric/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ textDecoration: "underline" }}>LinkedIn</p>
              </a>
              <a
                href="https://twitter.com/EricMilovan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ textDecoration: "underline" }}>Twitter</p>
              </a>
              <a
                href="https://dribbble.com/milovaneric"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ textDecoration: "underline" }}>Dribbble</p>
              </a>
            </div>
          </div>
          <div id={styles.desktop}>
            <a href={cv} target="_blank" rel="noopener noreferrer">
              <button id={!lightMode ? `` : `${styles.buttonLight}`}>
                View my CV
              </button>{" "}
            </a>
          </div>
        </div>
        <ContactForm lightMode={lightMode} />
        <div id={styles.mobile}>
          <div>
            <h2>Email</h2>
            <p>hello@erameister.com</p>
          </div>
          <div>
            <h2>Elsewhere</h2>
            <a
              href="https://www.linkedin.com/in/milovaneric/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p style={{ textDecoration: "underline", fontSize: "20px" }}>
                LinkedIn
              </p>
            </a>
            <a
              href="https://twitter.com/EricMilovan"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p style={{ textDecoration: "underline", fontSize: "20px" }}>
                Twitter
              </p>
            </a>
            <a
              href="https://dribbble.com/milovaneric"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p style={{ textDecoration: "underline", fontSize: "20px" }}>
                Dribbble
              </p>
            </a>
          </div>
          <a href={cv} target="_blank" rel="noopener noreferrer">
            <button id={!lightMode ? `` : `${styles.buttonLight}`}>
              View my CV
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import styles from "./Navigation.module.scss";
import image from "../../images/icon.png";
import sun from "../../images/sun-icon.svg";
import moon from "../../images/moon-icon.svg";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = ({ setLightMode, lightMode }) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [about, setAbout] = useState(false);
  const [contact, setContact] = useState(false);
  const [home, setHome] = useState(true);

  useEffect(() => {
    if (pathname === "/about") {
      setAbout(true);
      setContact(false);
      setHome(false);
    }
    if (pathname === "/contact") {
      setContact(true);
      setAbout(false);
      setHome(false);
    }
    if (pathname === "/") {
      setContact(false);
      setAbout(false);
      setHome(true);
    }

    setOpen(false);
  }, [pathname]);

  return (
    <nav className={`${styles.section} ${styles.active}`}>
      <Link to="/">
        <div className={styles.section__user}>
          <img src={image} alt="Milovan Eric" />
          <div className={styles.section__user__description}>
            <h3 id={lightMode ? `${styles.light}` : `${styles.dark}`}>
              Milovan Erić
            </h3>
            <p>
              Intrested in NFTs, WEB3,{" "}
              <br className={styles.section__user__description__mobile} />
              Metaverse, Blockchain and Startups
            </p>
          </div>
        </div>
      </Link>

      <div className={styles.section__navigation}>
        <Link to="/">
          {!lightMode && <p id={home ? `${styles.active}` : ""}>Home</p>}
          {lightMode && <p id={home ? `${styles.activeLight}` : ""}>Home</p>}
        </Link>
        <Link to="/about">
          {!lightMode && <p id={about ? `${styles.active}` : ""}>About Me</p>}
          {lightMode && (
            <p id={about ? `${styles.activeLight}` : ""}>About Me</p>
          )}
        </Link>
        <Link to="/contact">
          {!lightMode && (
            <p id={contact ? `${styles.active}` : ""}>Get In Touch</p>
          )}
          {lightMode && (
            <p id={contact ? `${styles.activeLight}` : ""}>Get In Touch</p>
          )}
        </Link>
      </div>

      <div className={styles.section__button}>
        {!lightMode && (
          <div className={styles.lightModeMobile}>
            <img
              src={sun}
              alt=""
              onClick={() => {
                setLightMode(!lightMode);
              }}
            />
          </div>
        )}
        {lightMode && (
          <div className={styles.lightModeMobile}>
            <img
              src={moon}
              alt=""
              onClick={() => {
                setLightMode(!lightMode);
              }}
            />
          </div>
        )}

        <div id={styles.mobileView}>
          {!open && (
            <i
              className="fa-solid fa-bars"
              id={lightMode ? `${styles.light}` : `${styles.dark}`}
              onClick={() => setOpen(!open)}
            ></i>
          )}
          {open && (
            <i
              className="fa-solid fa-x"
              id={lightMode ? `${styles.light}` : `${styles.dark}`}
              onClick={() => setOpen(!open)}
            ></i>
          )}
        </div>
      </div>
      {open && (
        <div
          className={styles.navigationMobile}
          id={lightMode ? `${styles.lightB}` : `${styles.darkB}`}
        >
          <Link to="/">
            {lightMode && <p id={home ? `${styles.active}` : ""}>Home</p>}
            {!lightMode && <p id={home ? "" : `${styles.active}`}>Home</p>}
          </Link>
          <Link to="/about">
            {lightMode && <p id={about ? `${styles.active}` : ""}>About Me</p>}
            {!lightMode && <p id={about ? "" : `${styles.active}`}>About Me</p>}
          </Link>
          <Link to="/contact">
            {lightMode && (
              <p id={contact ? `${styles.active}` : ""}>Get In Touch</p>
            )}
            {!lightMode && (
              <p id={contact ? "" : `${styles.active}`}>Get In Touch</p>
            )}
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navigation;

import Introduction from "../../components/Introduction/Introduction";
import TechnicalDetails from "../../components/TechnicalDetails/TechnicalDetails";
import BookRecommendation from "../../components/BookRecommendation/BookRecommendation";
import Footer from "../../components/Footer/Footer";

const AboutPage = ({ aboutIntro, lightMode }) => {
  return (
    <>
      <Introduction
        text1={aboutIntro.text1}
        text2={aboutIntro.text2}
        text3={aboutIntro.text3}
      />
      <TechnicalDetails />
      <BookRecommendation lightMode={lightMode} />
      <Footer lightMode={lightMode} />
    </>
  );
};

export default AboutPage;

import styles from "./App.module.scss";
import Navigation from "./components/Navigation/Navigation";
import { Routes, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage/AboutPage";
import HomePage from "./pages/HomePage/HomePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import { useEffect, useState } from "react";

function App() {
  const [lightMode, setLightMode] = useState(false);
  const homeIntro = {
    text1:
      "I’m Milovan Erić, a Serbia based product designer with over seven years of experience. I specialise in interface design for mobile and web-based applications with a focus on simplicity & usability.",
    text2:
      "Believing that software is an integral part of our lives and represents our future, I strive to create a products that is easily accessible to everyone.",
    text3:
      "Always looking for new lessons to learn, new things to build, new dots to connect, and new challenges to beat. These attitudes guide me to becoming the best version of myself. Wanna learn more about me? Feel free to contact me anytime.",
  };

  const aboutIntro = {
    text1:
      "Hi there, I'm a product designer based in Serbia with a passion for creating intuitive and user-friendly digital experiences. With years of experience in the field, I specialize in designing interfaces for web and mobile applications.",
    text2:
      "As a designer, I believe in putting the user at the center of everything I do. I strive to create products that are not only aesthetically pleasing but also functional and easy to use. Whether I'm designing a new feature or iterating on an existing one, my goal is to create solutions that meet the needs of my clients and their users.  When I'm not designing, you can find me exploring the latest design trends, experimenting with new tools and techniques, and seeking inspiration from the world around me. I'm always on the lookout for new challenges and opportunities to grow as a designer.",
    text3:
      "If you're looking for a product designer who is passionate about creating engaging and intuitive digital experiences, please feel free to get in touch. I'd love to hear from you and discuss how I can help bring your vision to life.",
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (lightMode) {
      body.style.background = "white";
    } else {
      body.style.background = "#0D0D11";
    }
  }, [lightMode]);

  return (
    <div
      className={lightMode ? `${styles.wrapperLight}` : `${styles.wrapperDark}`}
    >
      <Navigation lightMode={lightMode} setLightMode={setLightMode} />
      <div className={styles.content}>
        <Routes>
          <Route
            path="/"
            element={<HomePage homeIntro={homeIntro} lightMode={lightMode} />}
          />
          <Route
            path="/about"
            element={
              <AboutPage aboutIntro={aboutIntro} lightMode={lightMode} />
            }
          />
          <Route
            path="/contact"
            element={<ContactPage lightMode={lightMode} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;

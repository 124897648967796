import styles from "./BookRecommendation.module.scss";

const BookRecommendation = ({ lightMode }) => {
  return (
    <div
      className={styles.section}
      id={lightMode ? `${styles.bookHoverLight}` : `${styles.bookHoverDark}`}
    >
      <h2>Book Recommendations</h2>
      <a
        href="https://www.goodreads.com/book/show/840.The_Design_of_Everyday_Things"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          The Design of Everyday Things<span> by Don Norman</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/17269003-ui-is-communication?ref=nav_sb_ss_1_9"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          UI is Communication<span> by Everett N McKay</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/130730.Universal_Principles_of_Design?ac=1&from_search=true&qid=y8xyE8qzHu&rank=4"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          Universal Principles of Design
          <span> by William Lidwell, Kritina Holden, Jill Butler</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/43582738-the-scrum-fieldbook?ref=nav_sb_ss_1_12"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          Scrum Masterclass
          <span> by J.J. Sutherland</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/53816064-design-thinking?from_search=true&from_srp=true&qid=adjeE7us2f&rank=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          Design Thinking <span> by Milovan Dekić, Marko Jevtić</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/10127019-the-lean-startup?ref=nav_sb_ss_1_8"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          The Lean Startup
          <span> by Eric Ries</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/34267304-the-startup-way?ref=nav_sb_ss_2_9"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          The Startup Way
          <span> by Eric Ries</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/225111.The_Laws_of_Simplicity?ref=nav_sb_ss_1_15"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          The Laws of Simplicity <span> by John Maeda</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/10778139-100-things-every-designer-needs-to-know-about-people?ref=nav_sb_ss_1_12"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          100 Things Every Designer Needs to Know about People
          <span> by Susan M. Weinschenk</span>
        </p>
      </a>
      <a
        href="https://www.goodreads.com/book/show/7766737-the-fundamentals-of-product-design?ref=nav_sb_ss_1_23"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          The Fundamentals of Product Design <span> by Richard Morris</span>
        </p>
      </a>
    </div>
  );
};

export default BookRecommendation;

import Introduction from "../../components/Introduction/Introduction";
import Footer from "../../components/Footer/Footer";

const HomePage = ({ homeIntro, lightMode }) => {
  return (
    <>
      <Introduction
        text1={homeIntro.text1}
        text2={homeIntro.text2}
        text3={homeIntro.text3}
      />
      <Footer lightMode={lightMode} />
    </>
  );
};

export default HomePage;

import { useState, useEffect, useRef } from "react";
import styles from "./ContactForm.module.scss";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import { light } from "@mui/material/styles/createPalette";

const ContactForm = ({ lightMode }) => {
  const [dropDownValue, setDropDownValue] = useState("Choose from a list here");
  const [active, setActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState("$1K - $5K");
  const ref = useRef(null);
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      setStatus(false);
    }, 1500);
  }, [alert]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false);
    }
  };
  const handleChange = (event) => {
    setDropDownValue(event.target.value);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5hqb8fh",
        "template_xae3ugq",
        form.current,
        "amR8eGxmo-Jnz17eY"
      )
      .then(
        (success) => {
          setAlert(success);
        },
        (error) => {
          setStatus(error);
        }
      );
    e.target.reset();
  };

  return (
    <form className={styles.section} ref={form} onSubmit={sendEmail}>
      <div className={styles.mobile_wrapper}>
        <label>What's your name?</label>
        <input
          type="text"
          placeholder="Type your full name"
          required
          name="from_name"
        />
      </div>
      <div className={styles.mobile_wrapper}>
        <label>What's your email address?</label>
        <input
          type="text"
          name="reply_to"
          id=""
          placeholder="example@mail.com"
          required
        />
      </div>
      <div>
        <label>What have you budgeted for this project?</label>
        <input
          name="project_budget"
          value={selectedItem}
          style={{ display: "none" }}
        />
        <div className={styles.budget}>
          <div
            className={`${styles.budget__item} ${
              selectedItem === "$1K - $5K"
                ? !lightMode
                  ? styles.selectedDark
                  : styles.selectedLight
                : ""
            }`}
            onClick={() => setSelectedItem("$1K - $5K")}
          >
            $1K - $5K
          </div>
          <div
            className={`${styles.budget__item} ${
              selectedItem === "$5K - $10K"
                ? !lightMode
                  ? styles.selectedDark
                  : styles.selectedLight
                : ""
            }`}
            onClick={() => setSelectedItem("$5K - $10K")}
          >
            $5K - $10K
          </div>
          <div
            className={`${styles.budget__item} ${
              selectedItem === "$10K - $50K"
                ? !lightMode
                  ? styles.selectedDark
                  : styles.selectedLight
                : ""
            }`}
            onClick={() => setSelectedItem("$10K - $50K")}
          >
            $10K - $50K
          </div>
          <div
            className={`${styles.budget__item} ${
              selectedItem === "$50K - $100K"
                ? !lightMode
                  ? styles.selectedDark
                  : styles.selectedLight
                : ""
            }`}
            onClick={() => setSelectedItem("$50K - $100K")}
          >
            $50K - $100K
          </div>
          <div
            className={`${styles.budget__item} ${
              selectedItem === "$100K - ∞"
                ? !lightMode
                  ? styles.selectedDark
                  : styles.selectedLight
                : ""
            }`}
            onClick={() => setSelectedItem("$100K - ∞")}
          >
            $100K - ∞
          </div>
        </div>
      </div>
      {/* Dropdown
      <div>
        <label>What have you budgeted for this project?</label>
        <div className={styles.dropDownWrapper} name="project_budget">
          <div
            className={styles.dropDown}
            id={active ? `${styles.activeR}` : ``}
            ref={ref}
            onClick={() => setActive(true)}
          >
            {dropDownValue}
            <i className="fa-solid fa-chevron-down"></i>
          </div>
          <input
            type="text"
            value={dropDownValue}
            name="project_budget"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          {active && (
            <div
              className={styles.dropDownList}
              id={lightMode ? `${styles.light}` : `${styles.dark}`}
            >
              <p
                onClick={() => {
                  setDropDownValue("100$");
                }}
              >
                100$
              </p>
              <p
                onClick={() => {
                  setDropDownValue("200$");
                }}
              >
                200$
              </p>
              <p
                onClick={() => {
                  setDropDownValue("300$");
                }}
              >
                300$
              </p>
              <p
                onClick={() => {
                  setDropDownValue("more");
                }}
              >
                more
              </p>
            </div>
          )}
        </div>
      </div> */}
      <div className={styles.mobile_wrapper}>
        <label>Tell me about your project</label>
        <textarea
          placeholder="Please type your project description"
          required
          name="about_project"
        ></textarea>
      </div>
      <div className={styles.mobile_wrapper}>
        <button type="submit" id={!lightMode ? `` : `${styles.buttonLight}`}>
          Send Message
        </button>
      </div>

      {alert && (
        <Alert
          severity="success"
          className={`${styles.section__alert} ${
            alert
              ? styles["section__alert--active"]
              : styles["section__alert--not-active"]
          }`}
        >
          E-mail succesfully sent!
        </Alert>
      )}
      {status && (
        <Alert
          severity="error"
          className={`${styles.section__alert} ${
            status
              ? styles["section__alert--active"]
              : styles["section__alert--not-active"]
          }`}
        >
          An error occurred, try again later!
        </Alert>
      )}
    </form>
  );
};

export default ContactForm;
